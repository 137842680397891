
/* ----------- */

.banner {
  position: relative;
  text-align: center;
  height: 15vw;
  background-color: rgb(64, 60, 60); 
/*   background-color: rgb(249, 247, 247);
 */
  }

.titleBackground {
  margin-left: 1vw;
  padding-top: 2vw;
}

/* .titleImage {
  vertical-align: top;
  height: 8vw;
  width: 20vw;
  background-image: url(./images/mountains.png);
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: cover;
  background-color: transparent;
  display: inline-block;
} */

.titleImage {
  vertical-align: top;
  height: 8vw;
  width: 8vw;
  background-image: url(./images/eb_logo.png);
  background-repeat: no-repeat;
  background-position-y: 100%;
  background-size: cover;
  background-color: transparent;
  display: inline-block;
  border-radius: 6.5vw;
  -webkit-border-radius: 10vw;
  -moz-border-radius: 2.5vw;
}


@font-face {
  font-family: "BakerieSmooth";
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Black.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Bold.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Heavy.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Light.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Medium.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Regular.otf") format("truetype");
  src: local("BakerieThin"), url("./fonts/Bakerie/BakerieSmooth-Thin.otf") format("truetype");
}

.navLink {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 1vh;
  font-size: calc(15px + 0.390625vw);
}

.bannerTable {
  /* padding: .1vw; */
  height: 100%;
  width: 100%;
  font-size: 2vw;
 /*  margin-left: auto;*/
  margin-right: auto; 
  text-indent: 0em;
  border-spacing: 0em;
}
.bannerRow {
  border-width: 0em;
  background-color: rgb(64, 60, 60); 
}

.bannerCell {
  border-width: 0em;
}

.navTable {
  padding: .1vw;
  height: 100%;
  font-size: 2vw;
  margin-left: auto;
  margin-right: auto;
  text-indent: 0em;
}

.navTableRow {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.nav {
  background-color: lightgray;
}

.bodyText {
  padding: 3vw;
  font-family: 'BakerieSmooth';
  font-style: normal;
  font-weight: bold; 
  font-size: calc(18px + 0.390625vw);
}

.bodyPlainText {
  padding: 2vw;
  font-style: normal;
  font-size: calc(18px + 0.390625vw);
}

.quantityText {
  padding: 2vw;
  /* font-family: 'BakerieSmooth'; */
  font-style: normal;
  font-weight: bold;
  font-size: calc(18px + 0.390625vw);
}

.formHeaderText {
  padding-top: 1.5  vw;
  /* font-family: 'BakerieSmooth'; */
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  /* font-weight: bold; */
  font-size: calc(18px + 0.390625vw);
}

.footerText {
  padding: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5em;
}

.footer {
  background-color: lightgray;
}

.footerTable {
  padding: .5vw;
  height: 100%;
  font-size: 2vw;
  margin-left: auto;
  margin-right: auto;
  text-indent: 0em;

}

.footerTableRow {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.disclaimerContainer {
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.disclaimerText {
  padding: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  background-color: darkgrey;
  border-radius: 25px;
} 

.productTable {
  padding-top: 2vw;
  padding-left: 2vw;
  padding-right: 1vw;
  margin-left: 1vw;
}

.productTableRow {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.orderTableCellLeft {
  padding: 1vw;
  font-size: 1.5vw;
  width: 40vw;
}

.productTableCellLeft {
  padding: .5vw;
  font-size: calc(15px + 0.390625vw);
}

.productTableCellRight {
  padding: .5w;
}

.productTableCellQty {
  /* border: solid; */
  /* border-width: 10px; */
  /* border: 10px; */
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: auto;
  vertical-align: middle;
  font-size: calc(18px + 0.390625vw);
}

.productIngredients::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.productTitle {
  font-family: 'BakerieSmooth';
  font-style: normal;
  /* font-weight: bold; */
  font-size: calc(25px + 0.390625vw);
}

.contactText {
  padding: 2vw;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: calc(18px + 0.390625vw);
  margin-left: 1vw;
}

.productImage {
  width: 15vw;
  height: 14vw;
  text-align: center;
}

.socialMediaImage {
  max-width: 100%;
  height: 2vw;
}

.orderFormText {
  margin-left: auto;
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  font-size: calc(18px + 0.390625vw);
  font-family: Arial, Helvetica, sans-serif;
}

.orderFormInput {
  margin-right: auto;
  padding: .5vw;
  height: 100%;
  width: 60%;
  vertical-align: middle;
  font-size: calc(18px + 0.390625vw);
  font-family: Arial, Helvetica, sans-serif;
}

.modalDialog {
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid rgb(6, 6, 6);
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  z-index: 2;
}

.orderButton {
    background-color: gray;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-size: calc(18px + 0.390625vw);
    font-weight: bold;
}

.buttonArea {
  padding-top: 3vw;
  padding-bottom: 3vw;
  text-align: center;
}

.header {
  padding-bottom: 1vm; 
  position: sticky;
  top: 0vw;
  z-index: 1;
}

.noDotsList {
  list-style-type: square;
}
.orderCheckBox {
  width: 3vw;
  height: 3vw;
}

.news {
  background-color: rgb(132, 200, 234) ;
  padding: 2vw;
  font-family: 'BakerieSmooth';
  font-style: normal;
  font-weight: bold;
  font-size: calc(18px + 0.390625vw);
}

.scrollingTable {
  height: 90vh;
  overflow: scroll;
}

.topContainer {
  padding-top: 5px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 2vw;
  top: 2vh;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #6b6b6e;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  /* padding: .2vw .2vw 0; */
  font-size: calc(15px + 0.390625vw);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-menu-wrap{
  width: 15vw;
}

